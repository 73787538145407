import axios from "axios";
import Cookies from "js-cookie";
import LoginServices from "./LoginService";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 50000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // let adminInfo;
  // if (Cookies.get('adminInfo')) {
  //   adminInfo = JSON.parse(Cookies.get('adminInfo'));
  // }

  // let company;

  // if (Cookies.get('company')) {
  //   company = Cookies.get('company');
  // }
  let access_token;
  if (Cookies.get("access_token")) {
    access_token = Cookies.get("access_token");
  }
  return {
    ...config,
    headers: {
      authorization: access_token ? `Bearer ${access_token}` : null,
      access_token: access_token ? `${access_token}` : null,
    },
  };
});

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error?.response?.data?.etsyMessage === "invalid_token") {
      return refreshToken(originalRequest);
    }
    if (error?.response?.data?.message === "Quota reached") {
      if (window.location.pathname !== "/subscription")
        return (window.location.href = "/subscription");
      else document.dispatchEvent(new CustomEvent("Subscription.Required"));
    }
    return Promise.reject(error);
  }
);

const refreshToken = async (originalRequest) => {
  try {
    const tokenResponse = await LoginServices.authRefreshToken(
      Cookies.get("refresh_token")
    );
    const { access_token, refresh_token } = tokenResponse;
    Cookies.set("access_token", access_token);
    Cookies.set("refresh_token", refresh_token);
    originalRequest.headers.authorization = `Bearer ${access_token}`;
    originalRequest.headers.access_token = `${access_token}`;
    return await axios(originalRequest);
  } catch (error) {
    console.error("Failed to refresh token:", error);
    window.location.href = "/login";
    Promise.reject(error);
  }
};
const responseBody = (response) => response.data;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default requests;
