import requests from "./httpService";

const LoginServices = {
  updateMessagingPreference: async ({
    fullname,
    delivery_mail_template,
    messaging_active,
  }) => {
    return await requests.put(
      `https://etsybooking.vercel.app/api/auth/updateMessagingPreference`,
      { fullname, delivery_mail_template, messaging_active }
    );
  },
  getMessagingPreference: async () => {
    return await requests.get(
      `https://etsybooking.vercel.app/api/auth/getMessagingPreference`
    );
  },
  setToken: async (code) => {
    return await requests.get(
      `https://etsybooking.vercel.app/api/auth/callback?code=${code}&state=superstate`
    );
  },
  authRefreshToken: async (Token) => {
    return await requests.post(`auth/refresh`, {
      refresh_token: Token,
    });
  },
  getSubscriptions: async () => {
    return await requests.get(`auth/subscription`);
  },
  getQuota: async () => {
    return await requests.get(`auth/quota`);
  },
  cancelSubscription: async () => {
    return await requests.delete(`auth/subscription`);
  },
  createSubscription: async ({ package_id, interval }) => {
    return await requests.post(`auth/subscription`, {
      package_id,
      interval,
    });
  },
};

export default LoginServices;
